import Home from './pages/Home/Home.jsx';

const routes = [
	// { path: "/index6", component: Index6 },
	//  { path: "/index5", component: Index5 },
	//  { path: "/index4", component: Index4 },
	//  { path: "/index3", component: Index3 },
	//  { path: "/index2", component: Index2 },
	{ path: '/', component: Home },
];

export default routes;
