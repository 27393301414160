import React, { Component } from 'react';
import routes from './routes';
import {
	withRouter,
	Route,
	Switch,
	BrowserRouter as Router,
} from 'react-router-dom';

// Import Bootstrap css
import './theme.scss';

// Import themify Icon
import './assets/css/themify-icons.css';

// Import Custom Css
import './assets/css/style.css';

const linkedInRoutes = [
	{
		name: 'Kirk',
		path: '/linkedin-kirk',
		externalUrl: 'https://www.linkedin.com/in/kirk-duchesne/',
	},
	{
		name: 'Aliris',
		path: '/linkedin-aliris',
		externalUrl: 'https://www.linkedin.com/in/aliris-alicea-58a0a7b6/',
	},
	{
		name: 'Summer',
		path: '/linkedin-summer',
		externalUrl: 'https://www.linkedin.com/in/summer-duchesne-512940183/',
	},
];

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{routes.map((route, id) => (
							<Route path={route.path} component={route.component} key={id} />
						))}
					</Switch>
					{linkedInRoutes.map((route, id) => (
						<Route
							key={id}
							path={route.path}
							component={() => {
								window.location.replace(route.externalUrl);
								return null;
							}}
						/>
					))}
				</Router>
			</React.Fragment>
		);
	}
}

export default withRouter(App);
